import type { FetchOptions } from 'ofetch'
import { $fetch } from 'ofetch'
import AuthModule from '../repository/modules/auth'
import UserModule from '../repository/modules/user'
import BranchModule from '../repository/modules/branches'
import { useAuthCookie } from '~/composables/auth/useAuthCookie'
import { useErrorToast } from '~/store/errorToast'

export interface IApiInstance {
  auth: AuthModule
  user: UserModule
  branchService: BranchModule
}

export interface Provide {
  $api: IApiInstance
  $toast: any
}
declare module '#app' {
  interface NuxtApp {
    api: IApiInstance
  }
}
export default defineNuxtPlugin(({ $config }) => {
  const stageApiUrl = useCookie('stageApi')
  const errorToast = useErrorToast()
  const fetchOptions: FetchOptions = {
    baseURL: stageApiUrl.value || $config.public.BASE_API_URL,
    onRequest({ options }) {
      const token = computed(() => useAuthCookie().value)
      if (token.value) {
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${token.value}`,
        }
      }
    },
    onRequestError(context) {
      console.error(context)
    },

    onResponseError: (error) => {
      if (error.response.status === 404)
        showError({ statusCode: 404, statusMessage: 'Page Not Found' })

      if (error.response.status >= 500 && !error.response._data.message) {
        errorToast.show()
      }
    },
  }

  const apiFetcher = $fetch.create(fetchOptions)
  const modules: IApiInstance = {
    auth: new AuthModule(apiFetcher),
    user: new UserModule(apiFetcher),
    branchService: new BranchModule(apiFetcher),
  }

  return {
    provide: {
      api: modules,
      apiFetcher,
    },
  }
})
