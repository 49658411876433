
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91hash_93tGFJmSzpE6Meta } from "/app/pages/add/[slug]/[hash].vue?macro=true";
import { default as _91hash_934I80fnO3AsMeta } from "/app/pages/branches/[id]/add/[slug]/[hash].vue?macro=true";
import { default as analyticsGxdzpbU7zWMeta } from "/app/pages/branches/[id]/analytics.vue?macro=true";
import { default as _91landId_93gEV1pmnNUXMeta } from "/app/pages/branches/[id]/landings/[landId].vue?macro=true";
import { default as indexSqo4hSTLuuMeta } from "/app/pages/branches/[id]/landings/index.vue?macro=true";
import { default as requestszEupPftjwRMeta } from "/app/pages/branches/[id]/requests.vue?macro=true";
import { default as reviewsreqHIrAWo5Meta } from "/app/pages/branches/[id]/reviews.vue?macro=true";
import { default as settingshbrAykuPwCMeta } from "/app/pages/branches/[id]/settings.vue?macro=true";
import { default as staffLSVgWZDDyKMeta } from "/app/pages/branches/[id]/staff.vue?macro=true";
import { default as indexY9RTcbzbozMeta } from "/app/pages/branches/index.vue?macro=true";
import { default as forgotNziWY5IfaeMeta } from "/app/pages/forgot.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as profileupBlgdy3vLMeta } from "/app/pages/profile.vue?macro=true";
import { default as redirectvHLxODTt5rMeta } from "/app/pages/redirect.vue?macro=true";
export default [
  {
    name: "add-slug-hash",
    path: "/add/:slug()/:hash()",
    meta: _91hash_93tGFJmSzpE6Meta || {},
    component: () => import("/app/pages/add/[slug]/[hash].vue")
  },
  {
    name: "branches-id-add-slug-hash",
    path: "/branches/:id()/add/:slug()/:hash()",
    meta: _91hash_934I80fnO3AsMeta || {},
    component: () => import("/app/pages/branches/[id]/add/[slug]/[hash].vue")
  },
  {
    name: "branches-id-analytics",
    path: "/branches/:id()/analytics",
    meta: analyticsGxdzpbU7zWMeta || {},
    component: () => import("/app/pages/branches/[id]/analytics.vue")
  },
  {
    name: "branches-id-landings-landId",
    path: "/branches/:id()/landings/:landId()",
    meta: _91landId_93gEV1pmnNUXMeta || {},
    component: () => import("/app/pages/branches/[id]/landings/[landId].vue")
  },
  {
    name: "branches-id-landings",
    path: "/branches/:id()/landings",
    meta: indexSqo4hSTLuuMeta || {},
    component: () => import("/app/pages/branches/[id]/landings/index.vue")
  },
  {
    name: "branches-id-requests",
    path: "/branches/:id()/requests",
    meta: requestszEupPftjwRMeta || {},
    component: () => import("/app/pages/branches/[id]/requests.vue")
  },
  {
    name: "branches-id-reviews",
    path: "/branches/:id()/reviews",
    meta: reviewsreqHIrAWo5Meta || {},
    component: () => import("/app/pages/branches/[id]/reviews.vue")
  },
  {
    name: "branches-id-settings",
    path: "/branches/:id()/settings",
    meta: settingshbrAykuPwCMeta || {},
    component: () => import("/app/pages/branches/[id]/settings.vue")
  },
  {
    name: "branches-id-staff",
    path: "/branches/:id()/staff",
    meta: staffLSVgWZDDyKMeta || {},
    component: () => import("/app/pages/branches/[id]/staff.vue")
  },
  {
    name: "branches",
    path: "/branches",
    meta: indexY9RTcbzbozMeta || {},
    component: () => import("/app/pages/branches/index.vue")
  },
  {
    name: "forgot",
    path: "/forgot",
    meta: forgotNziWY5IfaeMeta || {},
    component: () => import("/app/pages/forgot.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginhHM0vSTW5jMeta || {},
    component: () => import("/app/pages/login.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: profileupBlgdy3vLMeta || {},
    component: () => import("/app/pages/profile.vue")
  },
  {
    name: "redirect",
    path: "/redirect",
    meta: redirectvHLxODTt5rMeta || {},
    component: () => import("/app/pages/redirect.vue")
  }
]